import { useContext, useState } from "react";
import { UserContext } from "../context/UserProvider";
import Swal from "sweetalert2";
import { Navigate } from "react-router-dom";

const Login = () => {
  const [_correo, set_Correo] = useState("");
  const [_clave, set_Clave] = useState("");
  const { user, iniciarSession } = useContext(UserContext);

  if (user != null) {
    return <Navigate to="/" />;
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    /*let request = {
            correo: _correo,
            clave:_clave
        }*/

    const request = {
      id: 0,
      idempresa: 0,
      nombres: "string",
      apellidos: "string",
      nombrecompleto: "string",
      correo: "admin@gmail.com",
      clave: "admin",
      idperfil: 0,
      perfil: "string",
      idNivelSeguridad: 0,
      fecharegistro: "2024-06-13T04:08:35.001Z",
      estado: true,
      estadotexto: "string",
      menu: [],
    };

    /*fetch("http://186.3.137.155:70/api/Proveedor/")
      .then((respuesta) => console.log(respuesta))
      .catch((error) => console.log(error));*/

    /*const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",       
    };

    fetch("https://localhost:44379/WeatherForecast", {
      method: "GET",     
    })
      .then((response) => {
        console.log(response.json());
      })      
      .catch((error) => {
        console.log(error);
        Swal.fire("Oops!", "No se pudo iniciar sesión", "error");
      });*/

    /*fetch("https://localhost:44379/WeatherForecast", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(request),
    })
      .then((response) =>
        response.ok ? response.json() : Promise.reject(response)
      )
      .then((dataJson) => {
        console.log(dataJson);
        if (dataJson.id == 0) {
          Swal.fire("Oops!", "No se encontró el usuario", "error");
        } else {
          iniciarSession(dataJson);
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire("Oops!", "No se pudo iniciar sesión", "error");
      });*/

    const raw = "";

    const requestOptions = {
      method: "GET",     
      redirect: "follow",
    };

    fetch("http://gmcmorenocordova-001-site2.htempurl.com/WeatherForecast", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                <div className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-4">Bienvenido</h1>
                    </div>
                    <form className="user" onSubmit={handleSubmit}>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control form-control-user"
                          aria-describedby="emailHelp"
                          placeholder="Correo"
                          value={_correo}
                          onChange={(e) => set_Correo(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control form-control-user"
                          placeholder="Contraseña"
                          value={_clave}
                          onChange={(e) => set_Clave(e.target.value)}
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-user btn-block"
                      >
                        {" "}
                        Ingresar{" "}
                      </button>
                    </form>
                    <hr></hr>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
